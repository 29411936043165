import React from "react"
import styled, { keyframes, css } from "styled-components"

import Background1 from "../../assets/images/honeycomb_background_1.svg"
import Background2 from "../../assets/images/honeycomb_background_2.svg"
import Background3 from "../../assets/images/honeycomb_background_3.svg"
import Background4 from "../../assets/images/honeycomb_background_4.svg"

const BackgroundSection = styled.section`
  div {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    height: 100vh;
    z-index: -1;
  }

  svg {
    width: 100vw;
  }
`

const shimmer = keyframes`
  0% {
    opacity: 100%;
  }
  25% {
    opacity: 60%;
  }
  50% {
    opacity: 30%;
  }
  75% {
    opacity: 60%;
  }
  100% {
    opacity: 100%;
  }
`

const AnimatedBackground3 = styled(Background3)`
  animation: ${shimmer} 3s infinite;
`

const AnimatedBackground4 = styled(Background4)`
  animation: ${shimmer} 4s infinite;
`

export default () => (
  <BackgroundSection>
    <div>
      <Background1 />
    </div>
    <div style={{ zIndex: -2 }}>
      <Background2 />
    </div>
    <div style={{ zIndex: -3 }}>
      <AnimatedBackground3 />
    </div>
    <div style={{ zIndex: -4 }}>
      <AnimatedBackground4 />
    </div>
  </BackgroundSection>
)
