import React, { useState, useEffect } from "react"
import styled, { keyframes, css } from "styled-components"
import DesignSvg from "../../assets/icons/design.svg"
import DrawnLightBulb from "../../assets/icons/drawn-lightbulb.svg"
import DrawnGear from "../../assets/icons/drawn-gear.svg"
import Theme from "../styles/Theme"

const Design = () => {
  let now = 0
  let next = 1

  const [iconState, updateIconState] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      ;[now, next] = [next, now]
      updateIconState(now)
    }, 4000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <DesignAnimation iconState={iconState}>
      <DesignSvg />
      {iconState ? (
        <DrawnGear
          style={{
            position: "absolute",
            left: "15%",
            top: "31%",
            height: "auto",
            width: "50%",
          }}
        />
      ) : (
        <DrawnLightBulb
          style={{
            position: "absolute",
            left: "35%",
            top: "17%",
            height: "auto",
            width: "50%",
          }}
        />
      )}
    </DesignAnimation>
  )
}

export default Design

// keyframes
const paperSlide = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(18px);
  }
`

const paperSlideBack = keyframes`
  from {
    transform: translateY(18px);
  }
  to {
    transform: translateY(0);
  }
`

const pencilSlide = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-90px);
  }
`

const pencilSlideBack = keyframes`
  from {
    transform: translateY(-90px);
  }
  to {
    transform: translateY(0);
  }
`
const rulerSlide = keyframes`
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(57px, 57px);
  }
`

const rulerSlideBack = keyframes`
  from {
    transform: translate(57px, 57px);
  }
  to {
    transform: translate(0, 0);
  }
`

// style
const DesignAnimation = styled.figure`
  position: relative;

  #design-paper {
    animation: ${props =>
      props.iconState
        ? css`${paperSlide} 0.5s ${Theme.easeInOutBack} forwards`
        : css`${paperSlideBack} 0.5s ${Theme.easeInOutBack} forwards`};
  }
  #design-pencil {
    animation: ${props =>
      props.iconState
        ? css`${pencilSlide} 0.5s ${Theme.easeInOutCubic} forwards`
        : css`${pencilSlideBack} 0.5s ${Theme.easeInOutCubic} forwards`};
  }
  #design-ruler {
    animation: ${props =>
      props.iconState
        ? css`${rulerSlide} 0.5s ${Theme.easeInOutCubic} 0.15s forwards`
        : css`${rulerSlideBack} 0.5s ${Theme.easeInOutCubic} forwards`};
  }
`
