import React from "react"
import styled, { keyframes } from "styled-components"
import BeeSvg from "../assets/images/bee-pure.svg"

const Loading = () => {
  return (
    <Spinner>
      <Bee />
    </Spinner>
  )
}

export default Loading

// style
const fade = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const Spinner = styled.div`
  display: flex;
  justify-content: center;
`

const Bee = styled(BeeSvg)`
  fill: white;
  max-width: 100px;
  animation: ${fade} 1s ease-in-out 0s infinite alternate;
`
