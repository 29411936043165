import React, { useState } from "react"
import styled from "styled-components"

import ContactForm from "../ContactForm"
import ContactFormConfirmation from "../ContactFormConfirmation"
import Container from "../Container"
import Section from "../Section"
import Spinner from "../Spinner"
import Theme from "../styles/Theme"
import CalendlyEmbed from "../CalendlyEmbed"

const FormTitle = styled.h2`
    padding-top: 40px;
    text-align: center;
  `, 
  FormDescription = styled.p`
    font-family: ${Theme.sentinel};
    color: ${Theme.navy};
    font-size: 2rem;
    line-height: 3.4rem;
    padding-bottom: 25px;
    text-align: center;
  `


export default styled(() => {

  return (
    <Section
      scrollBlock={true}
      bg={Theme.yellow}
    >
      <a name="contact" id="contact" className="anchor-link"></a>
      <Container maxWidth={650}>
          <>
            <FormTitle>
              Schedule A Free Consultation
            </FormTitle>
            <FormDescription>
              Select a time below, or send us a message at <a href="mailto: info@armyofbees.com">info@armyofbees.com</a>
            </FormDescription>
            <CalendlyEmbed />
          </>
      </Container>
    </Section>
  )
})``
