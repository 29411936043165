import React from "react"
import styled from "styled-components"

import ContactButton from "../ContactButton"
import Logo from "../../assets/images/aob-logo.svg"
import Theme from "../styles/Theme"

const HeadlineSection = styled.section`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .contents {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
  }

  svg {
    height: 110px;
  }

  // NOTE: moved these from their own styled components
  // to here because the GlobalStyles were more specific
  // and so I couldnt set the subheadline color
  // ... and the Headline and Subheadline components
  // were only used once
  h1 {
    text-align: center;
    font-weight: 700;
    font-size: 4.8rem;
    line-height: 5.8rem;
    padding: 80px 0 10px;
    text-shadow: 0.13em 0 white, 0 0.13em white, -0.13em 0 white,
      0 -0.13em white, -0.13em -0.13em white, -0.13em 0.13em white,
      0.13em -0.13em white, 0.13em 0.13em white;

    @media only screen and (max-width: 450px) {
      font-size: 3.6rem;
      line-height: 4.6rem;
    }
  }

  h2 {
    font-family: ${Theme.sentinel};
    color: ${Theme.gray};
    font-size: 3rem;
    text-align: center;
    line-height: 3.8rem;
    font-weight: 300;
    padding: 10px 0 80px;
    text-shadow: 0.07em 0 white, 0 0.07em white, -0.07em 0 white,
      0 -0.07em white, -0.07em -0.07em white, -0.07em 0.07em white,
      0.07em -0.07em white, 0.07em 0.07em white;
    margin-top: 0;
  }
`

export default () => (
  <HeadlineSection data-scroll-block>
    <div className="contents">
      <Logo/>
      <h1>Exceptional Digital Experiences</h1>
      <h2>Let us help you bring your vision to life</h2>
      <ContactButton/>
    </div>
  </HeadlineSection>
)
