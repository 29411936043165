import React from "react"
import styled, { css } from "styled-components"

import Theme from "../components/styles/Theme"
import Bee from "../assets/images/bee-sending.svg"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}
export default class ContactForm extends React.Component {
  state = {
    Name: "",
    Company: "",
    Email: "",
    "Project Details": "",
    "Budget Range": "",
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  checkStatus = fieldName => {
    return this.state[fieldName] !== ""
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.update("sending")
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(this.props.update("confirmed"))
      .catch(error => this.props.update("errored"))
  }

  render() {
    return (
      <Form
        name="contact"
        method="POST"
        action="/thanks/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={this.handleSubmit}
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="contact" />
        {/* Honeypot */}
        <div hidden>
          <label>
            <input name="bot-field" onChange={this.handleChange} />
          </label>
        </div>
        {/* Visible Form */}
        {Object.keys(this.state).map(fieldName => (
          <FormField key={fieldName}>
            <StyledInput
              type="text"
              id={fieldName}
              name={fieldName}
              onChange={this.handleChange}
              value={this.state[fieldName]} 
              placeHolder={fieldName} 
              isActive={this.checkStatus(fieldName)}
              required
            />
            <FormLine isActive={this.checkStatus(fieldName)} />
            <FloatLabel
              isActive={this.checkStatus(fieldName)}
              htmlFor={fieldName}
            >
              {fieldName}
              {["Name", "Email", "Project Details"].includes(fieldName) && (
                <span>*</span>
              )}
            </FloatLabel>
          </FormField>
        ))}
        <ButtonWrap>
          <button type="submit">Send Message</button>
          {/* <Bee /> */}
        </ButtonWrap>
      </Form>
    )
  }
}

// styles
const Form = styled.form`
  input,
  textarea {
    width: 100%;
    border: 0;
    outline: 0;
    background: none;
    color: ${Theme.navy};
    padding: 20px 0 6px;
    font-size: 2.4rem;
    line-height: 3.4rem;
    font-weight: 300;
    font-family: ${Theme.sentinel};
  }`

const FormField = styled.div`
  position: relative;
  margin-top: 28px;
`

const floating = `
  top: -7px;
  font-size: 1.4rem;
  font-weight: 400;
  color: rgb(44, 51, 59,.5);
  letter-spacing: .5px;
  `

const notFloating = `
  top: 23px;
  font-size: 2rem;
  font-weight: 400;
  color: rgb(44, 51, 59,.5);
  letter-spacing: .25px;
  `

const FloatLabel = styled.label`
  position: absolute;
  left: 1px;
  transition: ${Theme.transition};
  line-height: 2.7rem;
  font-family: ${Theme.whitney};
  font-weight: 400;
  color: #977B33;
  ${props =>
    props.isActive
      ? css`
          ${floating}
        `
      : css`
          ${notFloating}
        `}

  span {
    color: #c22f13;
    position: relative;
    top: -2px;
    left: 2px;
  }
`

const StyledInput = styled.input`
  &:focus + div {
    background: ${props => (props.isActive ? Theme.navy : "#C69A29")};
  }
`
const StyledTextarea = styled.textarea`
  &:focus + div {
    background: ${props => (props.isActive ? Theme.navy : "#C69A29")};
  }
`
const FormLine = styled.div`
  background: ${props => (props.isActive ? Theme.navy : "#C69A29")};
  height: 2px;
  border-radius: 5px;
  transition: ${Theme.transition};
`

const ButtonWrap = styled.div`
  position: relative;
  padding: 60px 0 120px;
  text-align: center;

  svg {
    display: none;
    position: absolute;
    left: 100%;
    top: 50%;
    transition: transform 200ms ease-in-out;
    transform: translate3d(0, -100%, 0);

    * {
      transition: transform 200ms ease-in-out;
    }

    @media (min-width: 1220px) {
      display: block;
    }
  }

  button {
    color: ${Theme.yellow};
    font-weight: bold;
    letter-spacing: 0.7px;
    min-width: 147px;
    & + svg {
      .sending-bee {
        transition: all 200ms ease-in-out;
      }
    }
    &:hover,
    &:focus {
      color: ${Theme.white};
      opacity: 1;
      & + svg {
        .sending-bee {
          fill: ${Theme.white};
          filter: drop-shadow(12px 12px 7px rgba(0, 0, 0, 0.5));
        }
      }
    }
  }
`
