import React from "react"
import loadable from "@loadable/component"

import Head from "../components/_Head"
import Layout from "../components/_Layout"
import HeadlineSection from "../components/landing/HeadlineSection"
import Services from "../components/landing/Services"
import Contact from "../components/landing/Contact"
import Background from "../components/landing/Background"

// no ssr for testimonials
const Testimonials = loadable(() => import("../components/landing/Slider"))

export default function IndexPage() {
  return (
    <Layout>
      <Head />
      <HeadlineSection />
      <Testimonials />
      <Services />
      <Contact />
      <Background />
    </Layout>
  )
}
