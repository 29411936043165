import React from "react"
import { InlineWidget } from "react-calendly"

const CalendlyEmbed = () => {
  return (
    <InlineWidget
      styles={{ height: "1010px" }}
      url="https://calendly.com/steve-aob/free-consultation?hide_gdpr_banner=1"
    />
  )
}

export default CalendlyEmbed
