import React from "react"
import styled, { keyframes } from "styled-components"
import DevelopSvg from "../../assets/icons/develop.svg"
// import Languages from "../../assets/images/language-icons.svg"

class Develop extends React.Component {
  injectLogos = () => {
    var icons = ["php", "ruby", "html", "css", "javascript"]

    var container = this.refs.wrapper

    function spawn() {
      var icon = icons[Math.floor(Math.random() * icons.length)]
      var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg")
      svg.setAttribute("class", "lang-icon")
      svg.style.left = 25 + Math.random() * 50 + "%"
      var use = document.createElementNS("http://www.w3.org/2000/svg", "use")
      use.setAttributeNS(
        "http://www.w3.org/1999/xlink",
        "href",
        "#lang-" + icon
      )
      svg.appendChild(use)
      container.appendChild(svg)

      setTimeout(function() {
        container.removeChild(svg)
      }, 3000)
    }

    setTimeout(function run() {
      spawn()
      setTimeout(run, 500 + Math.random() * 400)
    }, 500 + Math.random() * 400)

    spawn()
  }

  componentDidMount() {
    // this.injectLogos()
  }
  render() {
    return (
      <figure ref="wrapper">
        <AnimatedSvg />
        {/* <Languages style={{ position: "absolute" }} /> */}
      </figure>
    )
  }
}

export default Develop

// keyframes
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const scroll = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-22px);
  }
`

// styles
const AnimatedSvg = styled(DevelopSvg)`
  #develop-cog {
    animation: ${spin} 4s linear infinite;
    transform-origin: center;
    transform-box: fill-box;
  }

  #develop-code > g {
    animation: ${scroll} 2s linear infinite;
  }
`
