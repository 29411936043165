import React from "react"
import styled from "styled-components"
import { useSpring, animated, config } from "react-spring"
import Theme from "./styles/Theme"

const Section = props => {
  const { anchor, bg, color, className, scrollBlock, children } = props
  const fade = useSpring({
    from: {
      opacity: 0,
      // transform: "translate3d(0, 30px, 0)",
    },
    opacity: 1,
    // transform: "translate3d(0, 0, 0)",
    config: config.default,
  })
  return (
    <StyledSection
      id={anchor}
      bg={bg}
      color={color}
      style={fade}
      className={className}
      data-scroll-block={scrollBlock}
    >
      {children}
    </StyledSection>
  )
}

export default Section

// Styles
const StyledSection = styled(animated.section)`
  padding: 40px 0;
  background: ${props => props.bg || "none"};
  color: ${props => props.color || Theme.navy};

  .svg-use-css-color {
    fill: ${props => props.color || Theme.navy};
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${props => props.color || Theme.navy};
  }
`
