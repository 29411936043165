import React from "react"
import styled, { keyframes } from "styled-components"
import PerformSvg from "../../assets/icons/perform.svg"

const Perform = () => <AnimatedSvg />

export default Perform

// keyframes

const fly = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(90px);
  }
`
const waveBack = keyframes`
  from {
    transform: translate(-1px, 1px);
  }
  50% {
    transform: translate(0, 0);
  }
  to {
    transform: translate(1px, 0);
  }
`

const waveFront = keyframes`
  from {
    transform: translate(-2px, 0);
  }
  to {
    transform: translate(2px, 0);
  }
`

const orbit = keyframes`
  from {
    transform: rotate(0deg) translateX(1.2px) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translateX(1.2px) rotate(-360deg);
  }
`

const orbitFront = keyframes`
  from {
    transform: rotate(0deg) translateX(.5px) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translateX(.5px) rotate(-360deg);
  }
`

const flameStretch = keyframes`
  from {
    transform: scaleY(1);
    opacity: 0.8;
  }
  to {
    transform: scaleY(1.5);
    opacity: 1;
  }
`

const shake = keyframes`
  from {
    transform: translate3d(-.1px, .6px, 0);
  }

  33% {
    transform: translate3d(.2px, .4px, 0);
  }

  66% {
    transform: translate3d(-.4px, 0, 0);
  }

  to {
    transform: translate3d(.4px, .4px, 0);
  }
`

// styles
const AnimatedSvg = styled(PerformSvg)`
  #perform-stars > g {
    opacity: 0.8;
    animation: ${fly} 0.6s linear infinite;
  }

  #perform-exhaust-gray {
    /* animation: ${waveBack} 0.5s ease-in-out infinite alternate; */
    animation: ${orbit} 2s linear infinite reverse;
  }

  #perform-exhaust-white {
    /* animation: ${orbitFront} 1s linear infinite; */
    animation: ${waveFront} 1.25s ease-in-out 0.15s infinite alternate;
  }

  #perform-afterburner {
    transform-origin: center;
    transform-box: fill-box;
    animation: ${flameStretch} 0.1s ease-in infinite alternate;
  }

  #perform-rocketship {
    animation: ${shake} 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite
      alternate;
  }
`
