import React from "react"
import styled from "styled-components"
import Theme from "./styles/Theme"
import BeeSvg from "../assets/images/bee-thanks.svg"

const Confirmation = () => (
  <Wrap>
    <Message>
      <h2>Thanks for<br />reaching out!</h2>
      <p>We'll be in touch with you soon.</p>
    </Message>
    <Bee />
  </Wrap>
)

export default Confirmation

//styles
const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
  min-height: 388px;
`

const Message = styled.div`

  h2 {
    padding-top: 40px;
    color: ${Theme.white};
    text-align: center;
  }

  p {
    font-family: ${Theme.sentinel};
    color: ${Theme.gray};
    font-size: 2.4rem;
    line-height: 3.4rem;
    text-align: center;
  }
`


const Bee = styled(BeeSvg)`
  position: absolute;
  top: 50px;
  right: -210px;
`
