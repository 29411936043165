import React from "react"
import styled from "styled-components"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { Fade } from "react-reveal"

import Design from "../animated/Design"
import Research from "../animated/Research"
import Development from "../animated/Develop"
import Performance from "../animated/Perform"
import Theme from "../styles/Theme"

export default () => {
  const mobile = useMediaQuery(`(max-width:${Theme.breakpoint})`)
  return (
    <Services>
      <Title>What We Do</Title>
      <Fade left={!mobile}>
        <Service reverse>
          <ServiceIcon>
            <Research />
          </ServiceIcon>
          <ServiceCard>
            <div>
              <h2>Research</h2>
              <p>
                We start with market analysis and user research to truly
                understand your space, empathize with your target audience, and
                identify the highest impact opportunities.
              </p>
            </div>
            <div>
              <h3>Deliverables</h3>
              <ul>
                <li>Market analysis</li>
                <li>Competitive analysis</li>
                <li>SEO analysis</li>
                <li>User analysis</li>
              </ul>
            </div>
          </ServiceCard>
        </Service>
      </Fade>

      <Fade right={!mobile}>
        <Service>
          <ServiceIcon>
            <Design />
          </ServiceIcon>
          <ServiceCard>
            <div>
              <h2>Design</h2>
              <p>
                We guide you through an iterative design process, taking in
                feedback and using our expertise to quickly take you from your
                initial concept to a tangible vision.
              </p>
            </div>
            <div>
              <h3>Deliverables</h3>
              <ul>
                <li>Information architecture</li>
                <li>Wireframes</li>
                <li>Prototypes</li>
                <li>Style guides</li>
                <li>Visual designs</li>
              </ul>
            </div>
          </ServiceCard>
        </Service>
      </Fade>

      <Fade left={!mobile}>
        <Service reverse>
          <ServiceIcon>
            <Development iconOffset={10} />
          </ServiceIcon>
          <ServiceCard>
            <div>
              <h2>Develop</h2>
              <p>
                Our skilled developers bring your software to life, paying
                special attention to deadlines and budget to strategically
                release features against an ROI-based roadmap.
              </p>
            </div>
            <div>
              <h3>Deliverables</h3>
              <ul>
                <li>Marketing sites</li>
                <li>Custom web apps</li>
                <li>Mobile platforms</li>
                <li>CMS integrations</li>
              </ul>
            </div>
          </ServiceCard>
        </Service>
      </Fade>

      <Fade right={!mobile}>
        <Service>
          <ServiceIcon>
            <Performance />
          </ServiceIcon>
          <ServiceCard>
            <div>
              <h2>Perform</h2>
              <p>
                We stick with you after go-live to monitor performance and user
                feedback and continuously make data-driven improvements to drive
                business goals.
              </p>
            </div>
            <div>
              <h3>Deliverables</h3>
              <ul>
                <li>Business intelligence</li>
                <li>SEO</li>
                <li>Security & speed tuning</li>
                <li>Continuous support</li>
              </ul>
            </div>
          </ServiceCard>
        </Service>
      </Fade>
    </Services>
  )
}

// styles
const Title = styled.h2`
  font-size: 2.4rem;
  line-height: 3.4rem;
  text-align: center;
  padding-top: 40px;
  background: transparent;
  font-family: ${Theme.whitney};
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #68737d;

  ${Theme.smallScreen} {
    padding-bottom: 40px;
    padding-top: 0;
    color: ${Theme.white};
  }
`

const ServiceIcon = styled.div`
    padding: 0 0 0 45px;
    position: relative;
    top: -4px;

    svg {
      width: 175px;
      height: 180px;
    }

    ${Theme.smallScreen} {
      svg {
        width: 125px;
        height: 128px;
      }
    }

    @media only screen and (max-width: 560px) {
      padding-left: 0;
    }
  `,
  ServiceCard = styled.div`
    padding: 0 0 0 45px;

    @media only screen and (max-width: 560px) {
      padding: 0;
    }

    h2 {
      font-size: 3.6rem;
      line-height: 4.2rem;
      font-family: ${Theme.whitney};
      font-weight: bold;
      padding: 0;
    }

    p {
      font-family: ${Theme.sentinel};
      color: ${Theme.gray};
      font-size: 2.4rem;
      line-height: 3.4rem;
    }

    h3 {
      font-size: 1.8rem;
      line-height: 2.6rem;
      font-family: ${Theme.whitney};
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 1.5px;
    }

    ul {
      margin: 0 0 0 20px;
      padding: 0;
    }

    ul li {
      font-family: ${Theme.sentinel};
      color: ${Theme.gray};
      font-size: 2rem;
      line-height: 3rem;
      padding-top: 2px;
      padding-bottom: 2px;
    }

    ${Theme.bigScreen} {
    }

    ${Theme.smallScreen} {
    }
  `,
  Services = styled.section`
    position: relative;
    width: 100%;
    overflow: hidden;
    padding: 50px 0;
    background: rgba(245, 245, 245, 1);

    .slide {
      opacity: 0.01;
      transition: opacity 0.5s ease-in;
    }

    ${Theme.smallScreen} {
      background: ${Theme.navy};
      padding-bottom: 0;
    }
  `,
  Service = styled.div`
    position: relative;
    width: 100%;
    max-width: 850px;
    padding: 40px 45px 40px 0;
    box-shadow: 0 8px 60px 0 rgba(44, 51, 59, .11), 0 12px 90px 0 rgba(44, 51, 59, .11);
    border-radius: 4px;
    margin: 60px auto;
    background: #fff;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    z-index: 2;

    ${Theme.bigScreen} {
      ${(props) => props.reverse && "flex-direction: row-reverse"};
    }

    ${Theme.smallScreen} {
      border-left: 0;
      border-right: 0;
      margin-top: 0;
      border-radius: 0;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    @media only screen and (max-width: 560px) {
      flex-direction: column;
      padding-left: 5%;
      padding-right: 5%;

      h2 {
        padding-top: 15px;
      }
    }
  }`
