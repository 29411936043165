import React from "react"
import styled, { keyframes } from "styled-components"
import ResearchSvg from "../../assets/icons/research.svg"
import Theme from "../styles/Theme"

const Research = () => <AnimatedSvg />

export default Research

// keyframes
const interval = n => `${(n / 6) * 100}%`

const questionBubble = keyframes`
  ${interval(6)},
  ${interval(0)} {
    opacity: 0;
    transform: translate3d(0px, 10px, 0);
  }
  ${interval(1)},
  ${interval(5)} {
    opacity: 1;
    transform: translate3d(0px, 0px, 0);
  }
`

const cogSpin = keyframes`
  ${interval(1)} {
    opacity: 0;
    transform: rotate(0deg);
  }
  ${interval(1.5)},
  ${interval(5)} {
    opacity: 1;
  }
  ${interval(5.5)} {
    opacity: 0;
  }
  ${interval(6)} {
    opacity: 0;
    transform: rotate(360deg);
  }
`

const answerBubble = keyframes`
  ${interval(6)},
  ${interval(2)} {
    opacity: 0;
    transform: translate3d(0px, 10px, 0);
  }
  ${interval(3)},
  ${interval(5)} {
    opacity: 1;
    transform: translate3d(0px, 0px, 0);
  }
`
const lightbulb = keyframes`
  ${interval(6)},
  ${interval(3)} {
    opacity: 0;
    transform: translate3d(0px, 10px, 0);
  }
  ${interval(4)},
  ${interval(5)} {
    opacity: 1;
    transform: translate3d(0px, 0px, 0);
  }
`

// styles
const AnimatedSvg = styled(ResearchSvg)`
  #research-question-bubble {
    opacity: 0;
    animation: ${questionBubble} 4s ${Theme.easeOutBack} infinite;
  }

  #research-cog {
    opacity: 0;
    animation: ${cogSpin} 4s linear infinite;
    transform-origin: center;
    transform-box: fill-box;
  }

  #research-answer-bubble {
    opacity: 0;
    animation: ${answerBubble} 4s ${Theme.easeOutBack} infinite;
  }

  #research-lightbulb {
    opacity: 0;
    animation: ${lightbulb} 4s ${Theme.easeOutBack} infinite;
  }
`
